<template>
  <div class="d-flex flex-wrap" style="gap: 0.5rem;">
    <b-avatar
        v-for="defaultKpi of defaultKpis"
        :key="uuidV4()"
        v-b-tooltip.auto="defaultKpi.tooltip"
        :variant="`light-primary`"
        badge
        badge-offset="-2px"
        :badge-variant="getBadgeVariant(defaultKpi)">
          <span class="d-flex align-items-center">
            <feather-icon :icon="defaultKpi.icon"/>
          </span>
    </b-avatar>
  </div>
</template>

<script>
import {v4 as uuidV4} from 'uuid';

export default {
  name: "KpisShow",
  props: ['kpis'],
  data() {
    return {
      defaultKpis: [
        {
          target_type: null,
          module: 'auth',
          action: 'email-verify',
          modifier: 'complete',
          badgeIcon: 'CheckIcon',
          icon: 'MailIcon',
          tooltip: 'Email Verified'
        },
        {
          target_type: 'App\\Models\\Organization',
          module: 'organization',
          action: 'update',
          modifier: null,
          badgeIcon: 'PlusIcon',
          icon: 'BriefcaseIcon',
          tooltip: 'Organization Created'
        },
        {
          target_type: 'App\\Models\\Person',
          module: 'photo',
          action: 'store',
          modifier: null,
          badgeIcon: 'PlusIcon',
          icon: 'UsersIcon',
          tooltip: 'Person Photo Stored'
        },
        {
          target_type: 'App\\Models\\Identification',
          module: 'identification',
          action: 'store',
          modifier: null,
          badgeIcon: 'PlusIcon',
          icon: 'UserCheckIcon',
          tooltip: 'Identification Query'
        },
        {
          target_type: 'App\\Models\\Verification',
          module: 'verification',
          action: 'store',
          modifier: null,
          badgeIcon: 'PlusIcon',
          icon: 'ColumnsIcon',
          tooltip: 'Verification Query'
        },
        {
          target_type: null,
          module: 'billing',
          action: 'store',
          modifier: 'new',
          badgeIcon: 'PlusIcon',
          icon: 'PackageIcon',
          tooltip: 'Billing - New plan purchased'
        },
      ],
      uuidV4,
    }
  },
  methods: {
    getBadgeVariant(defaultKpi) {
      const find = this.kpis.find(kpi =>
          kpi.target_type === defaultKpi.target_type &&
          kpi.module === defaultKpi.module &&
          kpi.action === defaultKpi.action &&
          kpi.modifier === defaultKpi.modifier);
      return find ? 'success' : 'warning';
    }
  },
  setup() {

  }
}
</script>

<style scoped>

</style>
